<template>
  <div class="ParkRefundList-wrapper">
    <list
      ref="list"
      exportPermission="export"
      :exportUrl="exportUrl"
      :searchUrl="searchUrl"
      :searchParams.sync="searchParams"
      :headers="headers"
    >
      <template #searchSlot>
        <!-- <v-button @click="edit()">seahcj</v-button> -->
        <v-input label="订单编号" v-model="searchParams.serialNumber"></v-input>
        <v-input label="车牌号" v-model="searchParams.carLicence"></v-input>
        <v-input label="姓名" v-model="searchParams.userName"></v-input>
        <v-input label="手机号" v-model="searchParams.userPhone"></v-input>

        <!-- <v-select
          label="选择身份"
          v-model="searchParams.payCategory"
          :options="payCategoryOps"
        ></v-select> -->
        <!-- <v-select
          label="套餐类型"
          v-model="searchParams.suitType"
          :options="suitTypeOps"
        ></v-select> -->
         <v-select2
          label="所属组织"
          v-model="searchParams.communityId"
          v-bind="projectParams"
        />
         <v-select2
          label="租赁车场"
          v-model="searchParams.communityId"
          v-bind="projectParams"
        />
         <v-select2
          label="租赁车位"
          v-model="searchParams.communityId"
          v-bind="projectParams"
        />
        <v-datepicker
          label="办理时间"
          :startTime.sync="searchParams.startDate"
          :endTime.sync="searchParams.endDate"
        ></v-datepicker>
      </template>
      <template #operateSlot="scope">
        <v-button
          text="查看"
          type="text"
          permission="view"
          @click="edit(scope.row)"
        ></v-button>
      </template>
    </list>
  </div>
</template>

<script>
import { getParkRefundListURL, getSubjectListURL, getParkRefundExport } from './api'
import {
  payCategoryOps,
  payCategoryMap,
  payStatusMap,
  suitTypeOps,
  suitTypeMap,
  rentTypeMap
} from './map'

export default {
  name: 'ParkRefundList',
  data () {
    return {
      suitTypeOps: suitTypeOps.slice(0, -1),
      exportUrl: getParkRefundExport,
      searchUrl: getParkRefundListURL,
      payCategoryOps: payCategoryOps,
      projectParams: {
        searchUrl: getSubjectListURL,
        request: {
          text: 'communityName',
          value: 'communityId'
        },
        method: 'post',
        response: {
          text: 'name'
        }
      },
      searchParams: {
        suitType: undefined,
        userName: '',
        communityId: '',
        payCategory: undefined,
        serialNumber: '',
        carLicence: '',
        userPhone: '',
        startDate: '',
        endDate: ''
      },
      headers: [
        {
          prop: 'serialNumber',
          label: '订单编号',
          minWidth: '200'
        },
        {
          prop: 'cardNum',
          label: '车卡编号'
        },
        {
          prop: 'carNums',
          label: '车牌号'
        },
        {
          prop: 'payCategoryStr',
          label: '身份',
          formatter (row) {
            return payCategoryMap[row.payCategory]
          }
        },
        {
          prop: 'userName',
          label: '真实姓名'
        },
        {
          prop: 'userPhone',
          label: '手机号'
        },
        {
          prop: 'rentTypeStr',
          label: '租赁方式',
          formatter (row) {
            return rentTypeMap[row.rentType]
          }
        },
        {
          prop: 'suitTypeStr',
          label: '套餐类型',
          formatter (row) {
            return suitTypeMap[row.suitType]
          }
        },
        {
          prop: 'carSlotName',
          label: '车位/区域'
        },
        {
          prop: 'purchasePrice',
          label: '月均费用'
        },
        {
          prop: 'purchaseCount',
          label: '提前终止周期（月）'
        },
        {
          prop: 'orderMoneyTotal',
          label: '退款金额',
          align: 'right'
        },
        {
          prop: 'orgName',
          label: '所属组织'
        },
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'intime',
          label: '创建时间'
        }
      ]
    }
  },
  methods: {
    edit (row) {
      this.$router.push({
        name: 'parkReFundForm'
      })
    }
  }
}
</script>
